/**
 * Tracking mixin for Vue components
 * This mixin provides a trackEvent method that can be used in any component
 */

import trackingApi from '../api/tracking.js'

export default {
  methods: {
    /**
     * Track an event in the appropriate analytics system
     * @param {string} eventName - The name of the event
     * @param {Object} eventParams - Additional event parameters
     */
    trackEvent(eventName, eventParams = {}) {
      trackingApi.trackEvent(eventName, eventParams)
    }
  }
}