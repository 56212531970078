import { getCookie } from 'tiny-cookie'

export default {
  holdSlot(data, cb) {
    this._createHoldWithExpiry('/api/v1/booking_holds.json', data, cb)
  },

  holdSlotWithTimes(data, cb) {
    this._createHoldWithExpiry('/api/v1/booking_holds/from_times.json', data, cb)
  },

  _createHoldWithExpiry(path, data, cb) {
    const holdCode = getCookie('jammed_booking_hold')

    const createNewHold = () => {
      fetch(path, {
        method: 'POST',
        credentials: 'same-origin',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((resp) => {
        return resp.json()
      }).then((json) => cb(json))
    }

    if (holdCode) {
      // If there's an existing hold, expire it first
      const expirePath = `/api/v1/booking_holds/${holdCode}/expire.json`
      fetch(expirePath, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        // Create new hold after expiring the old one, regardless of expire result
        createNewHold()
      })
    } else {
      // No existing hold, create new one directly
      createNewHold()
    }
  },

  getSlot(slotCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}.json`
    fetch(path, {
      method: 'GET'
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  expireHold(code, token, cb) {
    const path = `/api/v1/booking_holds/${code}/expire.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ authenticity_token: token })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addStaffMember(slotCode, staffCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/staff.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: staffCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  removeStaffMember(slotCode, staffCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/staff/destroy.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: staffCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addExtra(slotCode, extraCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/add_extra.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  removeExtra(slotCode, extraCode, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/remove_extra.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
  changeExtraQuantity(slotCode, extraCode, quantity, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/change_extra_quantity.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: extraCode, quantity: quantity })
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  addCoupon(slotCode, body, cb) {
    const path = `/api/v1/booking_holds/${slotCode}/add_coupon.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  discoverCustomerCoupons(holdCode, body, cb) {
    const path = `/api/v1/booking_holds/${holdCode}/discover_customer_coupons.json`
    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },
}
