/**
 * Tracking helper for the booking app
 * This file handles sending tracking events to either Google Tag Manager or Facebook Pixel
 * if the studio has third-party tracking enabled
 */

/**
 * Check if the tracking data is available in the website info
 * @return {Object|null} Tracking configuration or null if not available
 */
const getTrackingConfig = () => {
  // Try to get the website info from the DOM
  const websiteInfoEl = document.querySelector("#website-info")
  if (!websiteInfoEl) return null

  try {
    const rejectedThirdPartyTrackingEl = document.querySelector("#rejected_third_party_tracking")
    if (rejectedThirdPartyTrackingEl && rejectedThirdPartyTrackingEl.getAttribute("data") === "true") {
      return {
        trackingEnabled: false,
        trackingVendor: null
      }
    }

    const websiteInfo = JSON.parse(websiteInfoEl.getAttribute("data"))
    // Return the tracking configuration if it exists
    return {
      trackingEnabled: websiteInfo.tracking_enabled,
      trackingVendor: websiteInfo.tracking_vendor
    }
  } catch (e) {
    console.error("Error parsing website info:", e)
    return null
  }
}

/**
 * Send an event to Google Tag Manager
 * @param {string} eventName - The name of the event
 * @param {Object} eventParams - Additional event parameters
 */
const sendGoogleEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams)
  }
}

/**
 *  Send to Fathom
 */
const sendFathomEvent = (eventName, eventParams = {}) => {
  if (window.fathom) {
    window.fathom.trackEvent(eventName, eventParams)
  }
}

/**
 * Track an event in the appropriate analytics system
 * @param {string} eventName - The name of the event
 * @param {Object} eventParams - Additional event parameters
 */
const trackEvent = (eventName, eventParams = {}) => {
  const trackingConfig = getTrackingConfig()

  // If tracking is not enabled or configured, do nothing
  if (!trackingConfig || !trackingConfig.trackingEnabled) {
    return
  }

  // Track the event in the appropriate system
  switch (trackingConfig.trackingVendor) {
    case 'google_tag_manager':
      sendGoogleEvent(eventName, eventParams)
      break
    case 'fathom':
      sendFathomEvent(eventName, eventParams)
      break
    default:
      // No tracking vendor configured
      break
  }
}

export default {
  trackEvent,
  getTrackingConfig,
}
